import YouGotMe from "./audio/YGM.mp3";
import Sail from "./audio/Sail.mp3";
import Again from "./audio/Again.mp3";
import Transistos from "./audio/Transistos.mp3";
import { v4 as uuidv4 } from "uuid";

function musicDetails() {
	return [
		{
			name: "Again",
			artist: "DAKA, 2019",
			audio: Again,
			id: uuidv4(),
			active: true,
		},
		{
			name: "You Got Me",
			artist: "DAKA, 2019",
			audio: YouGotMe,
			id: uuidv4(),
			active: true,
		},
		{
			name: "Sail",
			artist: "DAKA, 2019",
			audio: Sail,
			id: uuidv4(),
			active: true,
		},
		{
			name: "Transition",
			artist: "DAKA, 2019",
			audio: Transistos,
			id: uuidv4(),
			active: true,
		},
	];
}

export default musicDetails;
