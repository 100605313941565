import React, { useState, useEffect } from "react";
// Style
// import { Colours } from "../helpers/Colours";

// Sections
import Masthead from "../sections/Masthead";
import AboutMe from "../sections/AboutMe";
import Skills from "../sections/Skills";
import Projects from "../sections/Projects";

//Material Components
// import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ParticleBackground from "../components/ParticleBackground";

// Components

import NavBar from "../components/NavBar";
import CircleProgress from "../components/CircleProgress";
import NavMenu from "../components/NavMenu";
import Wave from "../components/Wave";
import Music from "../sections/Music";
import Contact from "../sections/Contact";
// import LogoDesign from "../sections/LogoDesign";

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.5s ease",
		width: "100%",
		backgroundColor: "#1B1B1B",
		minHeight: "100vh",
		zIndex: 0,
	},
	container: {
		position: "relative",
		width: "100%",
		color: "white",
	},
}));

export default function Home() {
	const classes = useStyles();
	const [menu, setMenu] = useState(false);

	useEffect(() => {
		//init()
	}, []);

	function handleMenuOpen() {
		setMenu(true);
	}

	function handleMenuClose() {
		setMenu(false);
	}

	return (
		<>
			<div className={classes.root}>
				<ParticleBackground />
				<CircleProgress />
				<Wave />
				<Container maxWidth="lg" className={classes.container}>
					<NavMenu
						menu={menu}
						handleMenuClose={handleMenuClose}
						handleMenuOpen={handleMenuOpen}
					/>
					<NavBar
						menu={menu}
						handleMenuClose={handleMenuClose}
						handleMenuOpen={handleMenuOpen}
					/>
					<Masthead />
					<Projects />
					<AboutMe />
					<Skills />
					<Music />
					{/* <LogoDesign /> */}
					<Contact />
				</Container>
			</div>
		</>
	);
}
