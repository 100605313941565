import React from "react";
import { Colours } from "../helpers/Colours";
import { motion } from "framer-motion";

import { makeStyles } from "@material-ui/core";
import Title from "../components/Title";

// Components

// Icons
import { ReactComponent as Logo } from "../images/html5.svg";
import { ReactComponent as Logo2 } from "../images/css3.svg";
import { ReactComponent as Logo3 } from "../images/javascript.svg";
import { ReactComponent as Logo4 } from "../images/react.svg";
import { ReactComponent as Logo5 } from "../images/csharp.svg";
import { ReactComponent as Logo6 } from "../images/flutter.svg";

import { ReactComponent as Logo7 } from "../images/pm2.svg";

import { skillsContainer } from "../animation";
import { useScroll } from "../useScroll";

// import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.5s ease",
		minHeight: "100vh",
		fontWeight: 400,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	content: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "70%",
		// backgroundColor: "lightblue",
		[theme.breakpoints.down("lg")]: {
			maxWidth: 850,
			width: "90%",
		},
		[theme.breakpoints.down("xs")]: {
			width: 25,
			marginRight: 15,
			flexDirection: "column",
		},
	},
	sectionWrapper: {
		width: 300,
		display: "flex",
		marginBottom: 20,
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			marginBottom: 10,
			justifyContent: "center",
		},
	},
	section: {
		[theme.breakpoints.down("xs")]: {
			marginTop: 25,
		},
	},
	mediaSection: {
		marginLeft: 40,
		justifyContent: "flex-end",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "center",
		},
	},
	itemText: {
		fontSize: 20,
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			fontSize: 14,
		},
	},
	itemImage: {
		marginRight: 30,
		width: 40,
		[theme.breakpoints.down("xs")]: {
			width: 25,
			marginRight: 15,
		},
	},
	mediaImage: {
		fill: Colours.grey,
		marginLeft: 30,
		width: 40,
		[theme.breakpoints.down("xs")]: {
			width: 25,
			marginLeft: 15,
		},
	},
	searchWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	search: {
		width: 200,
		height: 35,
		borderRadius: 25,
		marginTop: 15,
		border: "1px solid white",
		display: "flex",
		alignItems: "center",
	},
}));

function AboutMe() {
	const classes = useStyles();

	const [element, controls] = useScroll();

	const anim = {
		hidden: { opacity: [1, 0] },
		show: {
			opacity: [0.5, 1, 1, 1, 1, 1, 1, 1, 0.5],
			x: [-5, 5, -5],
			transition: { duration: 5, repeat: Infinity },
		},
	};

	const container = {
		hidden: { x: 0 },
		show: {
			x: 0,
			transition: {
				duration: 1.5,
				ease: "easeOut",
				staggerChildren: 0.25,
				when: "afterChildren",
			},
		},
	};

	const container2 = {
		hidden: { x: 0 },
		show: {
			x: 0,
			transition: {
				duration: 2,
				ease: "easeOut",
				staggerChildren: 0.25,
				when: "afterChildren",
			},
		},
	};

	function buildComputerSection() {
		return (
			<motion.div
				className={classes.section}
				variants={container}
				initial="hidden"
				animate="show"
			>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo className={classes.itemImage} fill="#E34F26" />
					<motion.div className={classes.itemText} variants={anim}>
						HTML 5
					</motion.div>
				</motion.div>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo2 className={classes.itemImage} fill="#1572B6" />
					<motion.div className={classes.itemText} variants={anim}>
						CSS/SASS
					</motion.div>
				</motion.div>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo3 className={classes.itemImage} fill="#F7DF1E" />
					<motion.div className={classes.itemText} variants={anim}>
						Javascript
					</motion.div>
				</motion.div>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo4 className={classes.itemImage} fill="#61DAFB" />
					<motion.div className={classes.itemText} variants={anim}>
						React JS
					</motion.div>
				</motion.div>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo4 className={classes.itemImage} fill="#61DAFB" />
					<motion.div className={classes.itemText} variants={anim}>
						React Native
					</motion.div>
				</motion.div>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo5 className={classes.itemImage} fill="#B366F6" />
					<motion.div className={classes.itemText} variants={anim}>
						C#
					</motion.div>
				</motion.div>
				<motion.div className={classes.sectionWrapper} variants={anim}>
					<Logo6 className={classes.itemImage} fill="#02569B" />
					<motion.div className={classes.itemText} variants={anim}>
						Flutter
					</motion.div>
				</motion.div>
			</motion.div>
		);
	}

	function buildMediaSection() {
		return (
			<motion.div
				className={classes.section}
				variants={container2}
				initial="hidden"
				animate="show"
			>
				{buildMediaItem("Music Composition")}
				{buildMediaItem("Audio Mixing & Mastering")}
				{buildMediaItem("Sound Effects")}
				{buildMediaItem("Audio Frequency Analysis")}
				{buildMediaItem("Video Editing")}
				{buildMediaItem("Image Manipulation")}
				{buildMediaItem("Logo Design")}
			</motion.div>
		);
	}

	function buildMediaItem(text) {
		return (
			<motion.div
				className={`${classes.sectionWrapper} ${classes.mediaSection}`}
				style={{}}
				variants={anim}
			>
				<motion.div className={classes.itemText} variants={anim}>
					{text}
				</motion.div>
				<Logo7 className={classes.mediaImage} />
			</motion.div>
		);
	}

	// function buildSearchBar() {
	// 	return (
	// 		<div className={classes.searchWrapper}>
	// 			<p>Search for Covid Results</p>
	// 			<div className={classes.search}>
	// 				<SearchIcon style={{ marginLeft: 10 }} />
	// 			</div>
	// 		</div>
	// 	);
	// }

	return (
		<section className={classes.root} id="section3">
			<Title title="Skills" subtitle="- Computer Science / Media -" />
			<motion.div
				className={classes.content}
				ref={element}
				animate={controls}
				variants={skillsContainer}
				initial="hidden"
			>
				{buildComputerSection()}
				{/* {buildSearchBar()} */}
				{buildMediaSection()}
			</motion.div>
		</section>
	);
}

export default AboutMe;
