import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

export const useScroll = () => {
	const controls = useAnimation();
	const smallWidth = window.innerWidth < 600;

	const [element, view] = useInView({ threshold: smallWidth ? 0.1 : 0.5 });
	if (view) {
		controls.start("show");
	} else {
		controls.start("hidden");
	}
	return [element, controls];
};
