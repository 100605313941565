import React from "react";
import { Colours } from "../helpers/Colours";
import { motion } from "framer-motion";

//Material
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-scroll";

import { fade } from "../animation";
import { useScroll } from "../useScroll";

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.5s ease",
		minHeight: "100vh",
		fontFamily: "'Lato', sans-serif",
		fontWeight: 700,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		marginTop: "-10vh",
		[theme.breakpoints.down("xs")]: {},
	},
	title: {
		fontSize: 130,
		color: Colours.white,
		lineHeight: 1,
		[theme.breakpoints.down("lg")]: {
			fontSize: 82,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 42,
		},
	},
	subtitle: {
		fontSize: 46,
		color: Colours.subtitle,
		[theme.breakpoints.down("lg")]: {
			fontSize: 26,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 22,
		},
	},
	buttonWrapper: {
		marginTop: 20,
	},
	button: {
		transition: "all 0.3s ease",
		width: 175,
		height: 45,
		borderRadius: 10,
		border: "1px solid white",
		cursor: "pointer",
		zIndex: 5,
		fontWeight: 500,
		fontSize: 16,
		lineHeight: 1.3,
		[theme.breakpoints.down("lg")]: {
			width: 150,
			height: 40,
			fontSize: 16,
		},
		[theme.breakpoints.down("xs")]: {
			width: 125,
			height: 35,
			fontSize: 14,
		},
	},
	rightButton: {
		backgroundColor: Colours.white,
		color: Colours.black,
		"&:hover": {
			backgroundColor: Colours.grey,
			color: Colours.white,
		},
	},
	leftButton: {
		backgroundColor: Colours.ProjectBackground,
		color: Colours.white,
		marginRight: 20,
		"&:hover": {
			backgroundColor: Colours.grey,
			// color: "black",
		},
	},
}));

function Masthead() {
	const classes = useStyles();
	const [element, controls] = useScroll();

	function buildButtons() {
		return (
			<div className={classes.buttonWrapper}>
				<Link
					className={classes.item}
					to="section1"
					spy={true}
					smooth={true}
					duration={750}
					// onClick={() => handleMenuClose()}
				>
					<Button className={`${classes.button} ${classes.leftButton}`}>
						My Projects
					</Button>
				</Link>

				<Link
					className={classes.item}
					to="section6"
					spy={true}
					smooth={true}
					duration={750}
					// onClick={() => handleMenuClose()}
				>
					<Button className={`${classes.button} ${classes.rightButton}`}>
						Contact Me
					</Button>
				</Link>
			</div>
		);
	}

	return (
		<motion.section
			ref={element}
			animate={controls}
			variants={fade}
			initial="show"
			className={classes.root}
			id="section0"
		>
			<motion.div className={classes.title}>David Daka</motion.div>
			<motion.div
				drag="x"
				dragConstraints={{ left: 0, right: 0 }}
				className={classes.subtitle}
			>
				Web & Mobile Developer
			</motion.div>
			{buildButtons()}
		</motion.section>
	);
}

export default Masthead;
