import React, { useState, useEffect, useRef } from "react";
import { Colours } from "../helpers/Colours";
import { motion, useAnimation } from "framer-motion";

import HeadphonesWhite from "../images/Headphones.png";
import HeadphonesBlack from "../images/HeadphonesBlack.png";
import Logo from "../images/Logo.png";

//Material
import { makeStyles } from "@material-ui/core";
import Title from "../components/Title";

// Components
import {
	musicContainer,
	leftFade,
	rightFade,
	musicPlayerFade,
} from "../animation";
import { useScroll } from "../useScroll";
import MusicPlayer from "../components/MusicPlayer";

import musicData from "../musicData";

import { useInView } from "react-intersection-observer";

// Styling

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.5s ease",
		minHeight: "100vh",
		fontWeight: 400,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	content: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("lg")]: {
			maxWidth: `90%`,
		},
		[theme.breakpoints.down("xs")]: {
			width: `100%`,
		},
	},
	info: {
		fontSize: "1.5em",
		color: Colours.white,
		lineHeight: 1.5,
		borderRadius: 20,
		backgroundColor: Colours.ProjectBackground,
		textAlign: "center",
		"& p": {
			marginBottom: 20,
		},
		[theme.breakpoints.down("lg")]: {
			width: "100%",
			fontSize: 16,
		},
		[theme.breakpoints.down("xs")]: {
			backgroundColor: "transparent",
			textAlign: "left",
			width: `100%`,
			fontSize: 14,
		},
	},
	infoWrapper: {
		padding: 50,
		paddingBottom: 0,
		[theme.breakpoints.down("lg")]: {
			fontSize: 16,
			padding: 30,
			paddingBottom: 0,
		},
		[theme.breakpoints.down("xs")]: {
			padding: 0,
			fontSize: 14,
		},
	},
	playerSection: {
		height: "100%",
		width: "100%",
		zIndex: 10,
	},
	songDetails: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		marginTop: 40,
		marginBottom: -40,
		[theme.breakpoints.down("lg")]: {
			marginBottom: 0,
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: 0,
		},
	},
	musicListWrapper: {
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center",
		margin: "40px 50px",
		// width: "100%",
		"& img": {
			margin: -20,
			width: 250,
			height: 250,
			objectFit: "contain",
			[theme.breakpoints.down("lg")]: {
				width: 100,
				height: 100,
				margin: 10,
			},
			[theme.breakpoints.down("xs")]: {
				width: 75,
				height: 75,
				margin: 10,
			},
		},
		[theme.breakpoints.down("lg")]: {
			margin: "40px 30px",
		},
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			margin: "0px 0px",
		},
	},
	musicList: {
		padding: 20,
		borderRadius: 20,
		backgroundColor: Colours.ProjectBackground,
		width: 375,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		"& p": {
			marginBottom: 10,
		},
		[theme.breakpoints.down("xs")]: {
			width: `100%`,
			borderRadius: 15,
			border: `1px solid ${Colours.grey}`,
			backgroundColor: Colours.ProjectForeground,
		},
	},
	mobileMusicList: {
		display: "flex",
		justifyContent: "space-around",
	},
}));

function Music() {
	const classes = useStyles();
	const smallWidth = window.innerWidth < 600;

	const [isInView, setIsInView] = useState(false);
	const controls = useAnimation();
	const [element, view] = useInView({ threshold: smallWidth ? 0.1 : 0.5 });

	useEffect(() => {
		if (view && !isInView) {
			controls.start("show");
			setIsInView(true);
		} else if (!view) {
			setIsInView(false);
			controls.start("hidden");
		}
	}, [view]);

	// State
	const [songs, setSongs] = useState(musicData());
	const [currentSong, setCurrentSong] = useState(songs[0]);
	const [isPlaying, setIsPlaying] = useState(false);
	const [songInfo, setSongInfo] = useState({
		currentTime: 0,
		duration: 0,
	});
	// Ref
	const audioRef = useRef(null);

	// Events
	function handleTimeUpdate(e) {
		const currentTime = e.target.currentTime;
		const duration = e.target.duration;
		setSongInfo({ ...songInfo, currentTime, duration });
	}

	async function handleSongEnd() {
		let currentIndex = songs.findIndex((song) => song.id === currentSong.id);
		await setCurrentSong(songs[(currentIndex + 1) % songs.length]);
		if (isPlaying) audioRef.current.play();
	}

	function buildMobileMusicList() {
		return (
			<div className={classes.musicListWrapper}>
				<motion.div className={classes.musicList} variants={leftFade}>
					<p> Adverts (Score & Sound Fx)</p>
					<p> (OSTs) Video Game Themes</p>
					<p> Original Soundtracks</p>
					<p> Sound Fx</p>
				</motion.div>
				<div className={classes.mobileMusicList}>
					<motion.img
						variants={leftFade}
						src={isPlaying ? HeadphonesWhite : HeadphonesBlack}
						alt="my profile"
					/>
					<motion.img variants={rightFade} src={Logo} alt="my profile" />
				</div>
			</div>
		);
	}

	function buildMusicList() {
		return (
			<div className={classes.musicListWrapper}>
				<motion.img
					variants={leftFade}
					src={isPlaying ? HeadphonesWhite : HeadphonesBlack}
					alt="my profile"
				/>
				<motion.div className={classes.musicList} variants={leftFade}>
					<p> Adverts (Scoring and Sound Fx)</p>
					<p> (OSTs) Video Game Themes</p>
					<p> Original Soundtracks</p>
					<p> Sound Fx</p>
				</motion.div>
				<img variants={rightFade} src={Logo} alt="my profile" />
			</div>
		);
	}

	return (
		<section className={classes.root} id="section4">
			<Title title="Music" subtitle="- Production / Composition -" />
			<motion.div
				className={classes.content}
				ref={element}
				animate={controls}
				variants={musicContainer}
				initial="hidden"
			>
				<motion.div
					className={classes.info}
					ref={element}
					animate={controls}
					variants={musicContainer}
					initial="hidden"
				>
					<div className={classes.infoWrapper}>
						<motion.p variants={leftFade}>
							Music has always been my greatest passion since I was a child. I
							am more drawn towards melodic arrangement, tones and rhythm.
						</motion.p>
						<motion.p variants={leftFade}>
							I am a Grade 5 Pianist, self-taught Guitarist & I have worked with
							Digital Audio Workstations (DAWs) for 10 years.
						</motion.p>
						<motion.p variants={leftFade}>
							Contact me for audio work. I am open to a large range of audio
							project work including:
						</motion.p>
					</div>
					{!smallWidth ? buildMusicList() : buildMobileMusicList()}
				</motion.div>
				<motion.div
					className={classes.playerSection}
					variants={musicPlayerFade}
				>
					<div className={classes.songDetails}>
						<h2>{currentSong.name}</h2>
						<h3>{currentSong.artist}</h3>
					</div>
					<MusicPlayer
						audioRef={audioRef}
						currentSong={currentSong}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						setSongInfo={setSongInfo}
						songInfo={songInfo}
						songs={songs}
						setCurrentSong={setCurrentSong}
						setSongs={setSongs}
					/>
				</motion.div>
			</motion.div>
			<audio
				onTimeUpdate={handleTimeUpdate}
				onLoadedMetadata={handleTimeUpdate}
				ref={audioRef}
				src={currentSong.audio}
				onEnded={handleSongEnd}
			></audio>
		</section>
	);
}

export default Music;
