import React from "react";
import { Colours } from "../helpers/Colours";

// Material
import { makeStyles } from "@material-ui/core/styles";
import NavButtons from "./NavButtons";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		transition: "all 0.3s ease",
		display: "flex",
		alignItems: "center",
		color: Colours.white,
		fontSize: 18,
		paddingRight: 24,
		height: "10vh",
		justifyContent: "space-between",
		fontWeight: 300,
		[theme.breakpoints.down("lg")]: {
			fontSize: 16,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 14,
		},
	},
	buttonGroup: {
		display: "flex",
		[theme.breakpoints.down("lg")]: {
			display: "none",
		},
	},
	item: {
		marginRight: 50,
	},
	menuButton: {
		transition: "all 0.7s ease",
		position: "fixed",
		right: 20,
		top: 20,
		zIndex: 99,
		opacity: (props) => props.opacity,
		[theme.breakpoints.up("lg")]: {
			display: "none",
		},
	},
	location: {
		// marginBottom: 20,
	},
}));

export default function NavBar({ menu, handleMenuOpen, handleMenuClose }) {
	const props = {
		opacity: menu ? 0 : 1,
	};

	const classes = useStyles(props);

	function buildNavButtons() {
		return (
			<div className={classes.buttonGroup}>
				<NavButtons handleMenuClose={handleMenuClose} />
			</div>
		);
	}

	function buildLocationGroup() {
		return (
			<div className={classes.location}>
				<div>ENGLAND, UK</div>
			</div>
		);
	}

	function buildMobileButton() {
		return (
			<MenuIcon
				className={classes.menuButton}
				onClick={() => handleMenuOpen()}
			/>
		);
	}

	return (
		<div className={classes.root}>
			{buildNavButtons()}
			{buildLocationGroup()}
			{buildMobileButton()}
		</div>
	);
}
