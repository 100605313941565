import React from "react";
import {
	motion,
	useViewportScroll,
	useSpring,
	useTransform,
} from "framer-motion";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		bottom: 30,
		right: 30,
		width: 70,
		height: 70,
		[theme.breakpoints.down("lg")]: {
			fontSize: 42,
			bottom: 10,
			right: 10,
			width: 40,
			height: 40,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 42,
			bottom: 5,
			right: 5,
			width: 25,
			height: 25,
		},
	},
}));

function CircleProgress() {
	const classes = useStyles();
	// const [isComplete, setIsComplete] = useState(false);
	const { scrollYProgress } = useViewportScroll();
	const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
	const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

	return (
		<>
			<svg className={classes.root} viewBox="0 0 60 60">
				<motion.path
					fill="none"
					strokeWidth="5"
					stroke="white"
					strokeDasharray="0 1"
					d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
					style={{
						pathLength,
						rotate: 90,
						translateX: 5,
						translateY: 5,
						scaleX: -1, // Reverse direction of line animation
					}}
				/>
				<motion.path
					fill="none"
					strokeWidth="5"
					stroke="white"
					d="M14,26 L 22,33 L 35,16"
					initial={false}
					strokeDasharray="0 1"
					// animate={{ pathLength: isComplete ? 1 : 0 }}
					animate={{ pathLength: 0 }}
				/>
			</svg>
		</>
	);
}

export default CircleProgress;
