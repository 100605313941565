import React from "react";
import { Colours } from "../helpers/Colours";
import { makeStyles } from "@material-ui/core";

// Material Comp
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";

const ICON_SIZE = "large";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "30vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		// backgroundColor: "lightBlue",
	},
	timeControl: {
		width: "75%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& input": {
			width: "100%",
			padding: "1rem 0rem",
		},
		"& p": {
			padding: "1rem",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	playControl: {
		width: "30%",
		padding: "1rem",
		display: "flex",
		justifyContent: "space-around",
		"& svg": {
			cursor: "pointer",
			color: Colours.white,
		},
		[theme.breakpoints.down("xs")]: {
			width: "80%",
		},
	},
}));

function MusicPlayer({
	currentSong,
	isPlaying,
	setIsPlaying,
	audioRef,
	setSongInfo,
	songInfo,
	songs,
	setCurrentSong,
	setSongs,
}) {
	const classes = useStyles();

	// Events
	function handleActiveLibrary(nextPrev) {
		const newSongs = songs.map((song) => {
			return song.id === nextPrev.id
				? { ...song, active: true }
				: { ...song, active: false };
		});
		setSongs(newSongs);
	}

	function handlePlaySong() {
		isPlaying ? audioRef.current.pause() : audioRef.current.play();
		setIsPlaying(!isPlaying);
	}

	function getTime(time) {
		return (
			Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
		);
	}

	function handleInputDrag(e, v) {
		setSongInfo({ ...songInfo, currentTime: v });
		audioRef.current.currentTime = v;
	}

	function handleDisplayPlayIcon() {
		if (isPlaying) {
			return <PauseIcon onClick={handlePlaySong} fontSize={ICON_SIZE} />;
		} else {
			return <PlayArrowIcon onClick={handlePlaySong} fontSize={ICON_SIZE} />;
		}
	}

	async function handleSkipTrack(direction) {
		let currentIndex = songs.findIndex((song) => song.id === currentSong.id);
		if (direction === "forward") {
			await setCurrentSong(songs[(currentIndex + 1) % songs.length]);
			handleActiveLibrary(songs[(currentIndex + 1) % songs.length]);
		} else {
			if (currentIndex === 0) {
				await setCurrentSong(songs[songs.length - 1]);
				handleActiveLibrary(songs[songs.length - 1]);

				if (isPlaying) audioRef.current.play();
				return;
			}
			await setCurrentSong(songs[(currentIndex - 1) % songs.length]);
			handleActiveLibrary(songs[(currentIndex - 1) % songs.length]);
		}
		if (isPlaying) audioRef.current.play();
	}

	// UI
	function buildMaterialSlider() {
		return (
			<Slider
				defaultValue={0}
				min={0}
				max={songInfo.duration || 0}
				onChange={handleInputDrag}
				value={songInfo.currentTime}
				valueLabelFormat={getTime(songInfo.currentTime)}
				valueLabelDisplay="auto"
			/>
		);
	}

	return (
		<div className={classes.container}>
			<div className={classes.timeControl}>
				<p>{getTime(songInfo.currentTime)}</p>
				{buildMaterialSlider()}
				<p>{songInfo.duration ? getTime(songInfo.duration) : "0:00"}</p>
			</div>
			<div className={classes.playControl}>
				<SkipPreviousIcon
					onClick={() => handleSkipTrack("back")}
					fontSize={ICON_SIZE}
				/>
				{handleDisplayPlayIcon()}
				<SkipNextIcon
					onClick={() => handleSkipTrack("forward")}
					fontSize={ICON_SIZE}
				/>
			</div>
		</div>
	);
}

export default MusicPlayer;
