import { REDUCER_TYPE } from "../helpers/Constants";

const INIT_STATE = {
    state: { stateObjects: [] },
    isLoading: true,
};

const detailReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case REDUCER_TYPE:
            return {
                ...state,
                state: action.payload,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export default detailReducer;
