import React from "react";
import { makeStyles } from "@material-ui/core";
import "../styles/app.scss";
// Pages
import Home from "../domain/Home";

const useStyles = makeStyles((theme) => ({
	body: {
		backgroundColor: "#1B1B1B",
		overflow: "hidden",
	},
}));

function App() {
	const classes = useStyles();

	return (
		<div className={classes.body}>
			<Home />
		</div>
	);
}

export default App;
