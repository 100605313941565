import React, { useState, useEffect } from "react";
import { Colours } from "../helpers/Colours";
import { motion, useAnimation } from "framer-motion";

//Material
import { makeStyles } from "@material-ui/core";
import { aboutUsContainer, leftFade, rightFade } from "../animation";
// import { useScroll } from "../useScroll";

import { useInView } from "react-intersection-observer";
const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "80%",
		marginTop: 100,
		display: "flex",
		justifyContent: (props) => props.justifyContent,
		flexDirection: (props) => props.flexDirection,
		[theme.breakpoints.down("lg")]: {
			width: "85%",
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: 75,
			marginTop: 0,
			width: "100%",
			maxWidth: 425,
			flexDirection: "column !important",
			alignItems: "center",
			backgroundColor: Colours.ProjectBackground,
			borderRadius: 15,
			border: `1px solid ${Colours.grey}`,
		},
	},
	leftContainer: {
		transition: "all 0.5s ease",
		backgroundColor: Colours.ProjectBackground,
		borderRadius: 15,
		height: 450,
		width: "60%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: `1px solid ${Colours.grey}`,
		[theme.breakpoints.down("lg")]: {
			height: 400,
		},
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			width: "100%",
			height: 250,
			// backgroundColor: "transparent",
		},
	},
	title: {
		fontSize: 32,
		textAlign: "center",
		fontWeight: 900,
		marginBottom: 30,
		flexDirection: (props) => props.flexDirection,
		[theme.breakpoints.down("lg")]: {
			fontSize: 26,
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: 10,
			fontSize: 22,
		},
	},
	innerLeftContainer: {
		height: "65%",
		width: "60%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "column",
		marginTop: 40,
		marginLeft: (props) => props.marginLeft,
		"& p": {
			fontSize: "1.3em",
			textAlign: "center",
			[theme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: 14,
			},
		},
		[theme.breakpoints.down("lg")]: {
			width: "65%",
			height: "70%",
		},
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			fontSize: 14,
			marginTop: 0,
			marginLeft: "0px !important",
			height: "70%",
			width: "100%",
			padding: 25,
			marginBottom: 40,
		},
	},
	rightContainer: {
		width: "35%",
		marginTop: 40,
		marginLeft: 50,
		marginRight: 50,
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		fontSize: 18,
		borderRadius: 15,
		"& li": {
			marginBottom: 10,
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
			},
		},
		[theme.breakpoints.down("lg")]: {
			fontSize: 16,
		},
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			justifyContent: "center",
			fontSize: 14,
			width: "100%",
			marginTop: -20,
			marginLeft: 0,
			marginRight: 0,
			padding: 30,
			// backgroundColor: Colours.ProjectForeground,
			backgroundColor: "transparent",
		},
	},
	largeContainer: {
		position: "absolute",
		backgroundColor: Colours.ProjectForeground,
		borderRadius: 15,
		top: 40,
		left: (props) => props.left,
		height: "100%",
		width: "calc(100% - 40px)",
		border: `1px solid ${Colours.grey}`,

		[theme.breakpoints.down("xs")]: {
			display: "none",
			flexDirection: "column",
		},
	},
	logo: {
		width: "100%",
		maxWidth: 250,
		maxHeight: 125,
		objectFit: "contain",
		[theme.breakpoints.down("xs")]: {
			marginBottom: 20,
			marginTop: (props) => props.marginTop,
		},
	},
	button: {
		transition: "all 0.3s ease",
		backgroundColor: Colours.darkBackground,
		color: Colours.white,
		textTransform: "none",
		width: "50%",
		minWidth: 150,
		height: 40,
		borderRadius: 15,
		zIndex: 5,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: Colours.ProjectForeground,
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: 20,
			width: 150,
		},
	},
}));

function ProjectItem({ id, title, image, description, link, skills }) {
	const props = {
		justifyContent: id % 2 === 0 ? "flex-start" : "flex-end",
		flexDirection: id % 2 === 0 ? "row" : "row-reverse",
		left: id % 2 === 0 ? 40 : 0,
		marginLeft: id % 2 === 0 ? 40 : 0,
		marginTop: id === 0 ? 0 : id === 1 ? -10 : -20,
	};
	const classes = useStyles(props);
	const smallWidth = window.innerWidth < 600;

	const [isInView, setIsInView] = useState(false);
	const controls = useAnimation();
	const [element, view] = useInView({ threshold: smallWidth ? 0.1 : 0.5 });

	useEffect(() => {
		if (view && !isInView) {
			console.log("happening");
			controls.start("show");
			setIsInView(true);
		} else if (!view) {
			console.log("not happening");
			setIsInView(false);
			controls.start("hidden");
		}
	}, [view]);

	// if (view && !isInView) {
	// 	console.log("happening");
	// 	controls.start("show");
	// 	setIsInView(true);
	// } else if (!view) {
	// 	console.log("not happening");
	// 	// setIsInView(false);
	// 	controls.start("hidden");
	// }

	function buildButton() {
		return (
			<a
				className={classes.button}
				target="_blank"
				rel="noopener noreferrer"
				href={link}
			>
				Visit Site
			</a>
		);
	}

	return (
		<motion.div
			ref={element}
			animate={controls}
			className={classes.root}
			variants={aboutUsContainer}
			initial="hidden"
		>
			<motion.div
				className={classes.leftContainer}
				ref={element}
				animate={controls}
				initial="hidden"
				variants={leftFade}
			>
				<motion.div variants={leftFade} className={classes.innerLeftContainer}>
					<img src={image} alt="title" className={classes.logo} />
					<p>{description}</p>
					{!smallWidth && buildButton()}
				</motion.div>
			</motion.div>
			<motion.div
				className={classes.rightContainer}
				ref={element}
				animate={controls}
				initial="hidden"
				variants={rightFade}
			>
				<div className={classes.title}>Skills Used</div>
				<ul>
					{skills.map((el) => (
						<li key={el}>{el}</li>
					))}
				</ul>
				{smallWidth && (
					<div style={{ display: "flex", justifyContent: "center" }}>
						{buildButton()}
					</div>
				)}
			</motion.div>
			<motion.div
				className={classes.largeContainer}
				ref={element}
				animate={controls}
				variants={rightFade}
				initial="hidden"
			></motion.div>
		</motion.div>
	);
}

export default ProjectItem;
