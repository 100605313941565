import React from "react";
import { Colours } from "../helpers/Colours";

import { Link } from "react-scroll";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	item: {
		transition: "all 0.2s ease",
		marginRight: 15,
		borderColor: "white",
		padding: "10px 15px",
		borderRadius: 10,
		zIndex: 5,
		cursor: "pointer",
		"&:hover": {
			backgroundColor: Colours.ProjectForeground,
			transition: "all 0.2s ease",
		},
	},
}));

function NavButtons({ handleMenuClose }) {
	const classes = useStyles();

	return (
		<>
			<Link
				className={classes.item}
				to="section1"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				PROJECTS
			</Link>
			<Link
				className={classes.item}
				to="section2"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				ABOUT
			</Link>
			<Link
				className={classes.item}
				to="section3"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				SKILLS
			</Link>
			<Link
				className={classes.item}
				to="section4"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				MUSIC
			</Link>
			{/* <Link
				className={classes.item}
				to="section5"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				LOGO DESIGN
			</Link> */}
			<Link
				className={classes.item}
				to="section6"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				CONTACT
			</Link>
		</>
	);
}

export default NavButtons;
