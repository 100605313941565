import React, { useState, useEffect } from "react";
import { Colours } from "../helpers/Colours";
import { motion } from "framer-motion";

//Material
import { makeStyles, TextField } from "@material-ui/core";
import Title from "../components/Title";

// Components
import { ContactContainer, leftFade, rightFade } from "../animation";
// import { useScroll } from "../useScroll";
// Styling

import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

import emailjs from "emailjs-com";
import { ReactComponent as Logo } from "../images/handshake.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.3s ease",
		minHeight: "100vh",
		fontWeight: 400,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		paddingBottom: 100,
		marginLeft: 15,
		marginRight: 15,
		[theme.breakpoints.down("xs")]: {
			marginLeft: 0,
			marginRight: 0,
		},
	},
	content: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		width: "70%",
		marginTop: 50,
		[theme.breakpoints.down("xs")]: {
			width: `100%`,
			flexDirection: "column",
		},
	},
	info: {
		zIndex: 100,
		color: Colours.white,
		width: "100%",
		[theme.breakpoints.down("xs")]: {},
	},
	formWrapper: {
		backgroundColor: Colours.ProjectForeground,
		width: "100%",
		height: "100%",
		padding: 50,
		borderRadius: 25,
		"& p": {
			marginBottom: 40,
			fontSize: 18,
		},
		[theme.breakpoints.down("xs")]: {
			// backgroundColor: "transparent",
			padding: 20,
			"& p": {
				marginBottom: 25,
				fontSize: 14,
			},
		},
	},
	form: {
		marginBottom: 40,
		width: "100%",
		color: Colours.white,
		backgroundColor: Colours.ProjectForeground,
		borderRadius: 10,
		"& .MuiInputBase-input": {
			color: Colours.white,
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
				height: 15,
			},
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: Colours.white,
		},
		"& .MuiInputLabel-outlined": {
			color: Colours.white,
			[theme.breakpoints.down("xs")]: {
				fontSize: 14,
			},
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: 25,
		},
	},
	nameAndFormWrapper: {
		display: "flex",
		flexDirection: (props) => props.flexDirection,
	},
	buttonWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	button: {
		transition: "all 0.3s ease",
		backgroundColor: Colours.darkBackground,
		color: Colours.white,
		textTransform: "none",
		width: "40%",
		minWidth: 150,
		height: 50,
		borderRadius: 15,
		fontSize: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		textDecoration: "none",
		borderColor: "transparent",
		cursor: "pointer",
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
			height: 40,
		},
		"&:hover": {
			backgroundColor: Colours.buttonHover,
		},
	},
	thankYou: {
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		"& p": {
			fontSize: 22,
			[theme.breakpoints.down("xs")]: {
				fontSize: 14,
			},
		},
	},
	icon: {
		width: 55,
	},
}));

function Contact() {
	const smallWidth = window.innerWidth < 600;
	const props = {
		flexDirection: smallWidth ? "column" : "row",
	};
	const classes = useStyles(props);

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const [isInView, setIsInView] = useState(false);
	const controls = useAnimation();
	const [element, view] = useInView({ threshold: smallWidth ? 0.1 : 0.5 });

	useEffect(() => {
		if (view && !isInView) {
			controls.start("show");
			setIsInView(true);
		} else if (!view) {
			setIsInView(false);
			controls.start("hidden");
		}
	}, [view]);

	const onNameChange = (e) => {
		setName(e.target.value);
	};

	const onEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const onSubjectChange = (e) => {
		setSubject(e.target.value);
	};

	const onMessageChange = (e) => {
		setMessage(e.target.value);
	};

	function submitEmail(e) {
		e.preventDefault();
		emailjs
			.sendForm(
				"service_d7xipmv",
				"template_5qiqxim",
				e.target,
				"user_4J6Y8tOQWWRYW4OjFRngW"
			)
			.then(
				(result) => {
					console.log(result.text);
					setFormSubmitted(true);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
	}

	function buildEntireForm() {
		return (
			<>
				<motion.p variants={leftFade}>
					I would love to hear from you, let's start building a project
					together!
				</motion.p>
				<motion.form variants={rightFade} onSubmit={submitEmail}>
					<motion.div
						variants={leftFade}
						className={classes.nameAndFormWrapper}
					>
						{buildNameForm()}
						{buildSubjectForm()}
					</motion.div>
					{buildEmailForm()}
					{buildMessageForm()}
					<motion.div variants={leftFade} className={classes.buttonWrapper}>
						<button type="submit" className={classes.button}>
							Submit
						</button>
					</motion.div>
				</motion.form>
			</>
		);
	}

	function buildNameForm() {
		return (
			<TextField
				id="outlined-secondary"
				label="Full Name"
				name="name"
				placeholder="Enter your name"
				variant="outlined"
				color="secondary"
				type="text"
				required
				value={name}
				onChange={onNameChange}
				className={classes.form}
				style={{ marginRight: 30 }}
			/>
		);
	}

	function buildSubjectForm() {
		return (
			<TextField
				id="outlined-secondary"
				label="Subject"
				name="subject"
				placeholder="Add an subject header"
				variant="outlined"
				color="secondary"
				type="text"
				required
				value={subject}
				onChange={onSubjectChange}
				className={classes.form}
			/>
		);
	}

	function buildEmailForm() {
		return (
			<TextField
				id="outlined-secondary"
				label="Email Address"
				name="email"
				placeholder="Enter email address"
				variant="outlined"
				color="secondary"
				type="email"
				required
				value={email}
				onChange={onEmailChange}
				className={classes.form}
			/>
		);
	}

	function buildMessageForm() {
		return (
			<TextField
				id="outlined-secondary"
				label="Write a message"
				name="message"
				placeholder="Type text here"
				variant="outlined"
				color="secondary"
				type="email"
				required
				multiline
				value={message}
				onChange={onMessageChange}
				className={classes.form}
			/>
		);
	}

	function buildThankYouMessage() {
		return (
			<div className={classes.thankYou}>
				<p>Thank you for your email, I will be in touch with you soon!</p>
				<Logo className={classes.icon} />
			</div>
		);
	}

	return (
		<section className={classes.root} id="section6">
			<Title title="Contact" subtitle="- daviddjdaka@gmail.com -" />
			<motion.div
				className={classes.content}
				ref={element}
				animate={controls}
				variants={ContactContainer}
				initial="show"
			>
				<motion.div
					className={classes.info}
					ref={element}
					animate={controls}
					variants={ContactContainer}
					initial="show"
				>
					<motion.div className={classes.formWrapper}>
						{!formSubmitted ? buildEntireForm() : buildThankYouMessage()}
					</motion.div>
				</motion.div>
			</motion.div>
		</section>
	);
}

export default Contact;
