export const Colours = {
	primary: "#FFF",
	secondary: "#141414",
	white: "#FFF",
	black: "#000",
	grey: "#515151",
	green: "#6bc704",
	background: "#141414",
	darkBackground: "#0c0c0c",
	formText: "#858585",
	subtitle: "#D5D5D5",
	buttonHover: "#1f1f1f",
	bodyText: "rgba(255, 255, 255, 0.7)",
	imageBorder: "rgba(255, 255, 255, 0.5)",
	imageShadow: "rgba(255, 255, 255, 0.15)",
	ProjectBackground: "rgba(255, 255, 255, 0.05)",
	ProjectBackground2: "rgba(255, 255, 255, 0.10)",
	ProjectForeground: "rgba(255, 255, 255, 0.10)",
	lineStroke: "rgba(3, 181, 252, 0.5)",
	NavBackground: "rgba(0, 0, 0, 0.9)",
};
