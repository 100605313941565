import Retrobase from "../images/Retrobase.png";
import Xzalor from "../images/XzalorTitle.png";
import SoundRails from "../images/soundrails.png";
import HuddersfieldUniRentals from "../images/huddersfieldUniRentals.png";

import BrooksHomes from "../images/brooks.png";
import EHA from "../images/ehaLogo.png";
import SimpleSuccess from "../images/SimpleSuccess.png";
import Guess from "../images/Guess.png";

export const Projects = [
	{
		id: 0,
		title: "Retrobase",
		image: Retrobase,
		description:
			"Search for new and popular games by category. Save your favourites within our database! ",
		link: "https://www.theretrobase.com/",
		skills: [
			"Using APIs to fetch and read data",
			"Filtering lists through an extensive database",
			"Writing favourites games to Firebase Database",
			"Multiple cases of different styling techniques using grids",
		],
	},
	{
		id: 1,
		title: "Xzalor Chapter I: Tales of Ajorn",
		image: Xzalor,
		description:
			"Battle endless waves of unique and challenging enemies! Use special skills to save Ajorn!",
		link: "https://play.google.com/store/apps/details?id=com.Daka.Xzalor",
		skills: [
			"Programming in Unity using C#",
			"Sprite animation using keyframes",
			"Sound Effects & soundtrack composition/production",
			"Heavy use of mathematic equations for game scaling",
		],
	},
	{
		id: 2,
		title: "Sound Rails",
		image: SoundRails,
		description:
			"Purchase audio gear with confidence! Music equipment review and comparison",
		link: "https://soundrails.com/",
		skills: [
			"Wordpress",
			"SEO and Readability",
			"Using an email collection service",
			"Plenty of research to find valuable knowledge and content",
		],
	},
	{
		id: 3,
		title: "Huddersfield Uni Rentals",
		image: HuddersfieldUniRentals,
		description:
			"Affordable student accommodation from only £75 per person per week!",
		link: "https://huddersfieldunirentals.com//",
		skills: [
			"Image manipulation and filtering",
			"Working closely with a client and adhering to their feedback",
			"Logo Design",
		],
	},
];

export const Logos = [
	{
		id: 0,
		title: "The Brooks Homes",
		image: BrooksHomes,
	},
	{
		id: 1,
		title: "Essential Health Advice",
		image: EHA,
	},
	{
		id: 2,
		title: "The Simple Success",
		image: SimpleSuccess,
	},
	{
		id: 3,
		title: "Huddersfield Uni Rentals",
		image: HuddersfieldUniRentals,
	},
	{
		id: 4,
		title: "Sound Rails",
		image: SoundRails,
	},
	{
		id: 5,
		title: "Guess",
		image: Guess,
	},
];

export const PageContent = {
	aboutMe1:
		"You will always have access to a fully qualified pharmacist 100 hours every week. We are a fully registered pharmacy with the General Pharmaceutical Council and are therefore able to dispense all NHS and private prescriptions. We are also able to offer the FREE smoking cessation advice and free nursing and care home services.",
	aboutMe2:
		"Greenhead Pharmacy was founded with the concept of “Helping to make Huddersfield healthier”. We feel that you should not only visit a pharmacy when you are ill but also when you are well, to ensure you remain fit and healthy. This is why alongside the regular services you would expect to find in a pharmacy such as the dispensing of prescriptions and over the counter medication advice we have launched a number of FREE services such as FREE blood pressure monitoring and FREE diabetes screening, and Free delivery service",
};
