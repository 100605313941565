import React from "react";
import { Colours } from "../helpers/Colours";
import { motion } from "framer-motion";

//Material
import { makeStyles } from "@material-ui/core";

import { fade } from "../animation";
import { useScroll } from "../useScroll";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		marginTop: 100,
		marginBottom: 100,
		[theme.breakpoints.down("xs")]: {
			marginTop: 50,
			marginBottom: 50,
		},
	},
	title: {
		fontSize: 130,
		fontFamily: "'Lato', sans-serif",
		fontWeight: 900,
		textAlign: "center",
		color: Colours.white,
		[theme.breakpoints.down("lg")]: {
			fontSize: 82,
			lineHeight: 1,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 42,
			lineHeight: 1,
		},
	},
	subtitle: {
		fontSize: 32,
		marginTop: 10,
		color: Colours.subtitle,
		zIndex: 5,
		[theme.breakpoints.down("lg")]: {
			fontSize: 26,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
		},
	},
}));

function Title({ title, subtitle }) {
	const classes = useStyles();

	const [element, controls] = useScroll();

	return (
		<motion.div
			ref={element}
			animate={controls}
			variants={fade}
			initial="hidden"
			className={classes.root}
		>
			<motion.div className={classes.title}>{title}</motion.div>
			<motion.div
				className={classes.subtitle}
				drag="x"
				dragConstraints={{ left: 0, right: 0 }}
				whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
			>
				{subtitle}
			</motion.div>
		</motion.div>
	);
}

export default Title;
