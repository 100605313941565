import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Colours } from "./helpers/Colours";
import App from "./app/App";

import { BrowserRouter } from "react-router-dom";

import { createStore, applyMiddleware, compose } from "redux";
import allReducers from "./reducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

const rootElement = document.getElementById("root");

const theme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 920,
			lg: 1240,
			xl: 1240,
		},
	},
	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		allVariants: {
			color: Colours.formTextColour,
		},
		body1: {
			color: Colours.formTextColour,
		},
		body2: {
			color: Colours.formTextColour,
		},
		h1: {
			fontSize: 130,
		},
		h2: {
			color: Colours.primary,
			fontWeight: "bold",
			fontSize: "230%",
			marginBottom: 10,
		},
		h3: {
			color: Colours.primary,
			fontWeight: "bold",
			fontSize: "140%",
			marginBottom: 10,
		},
		h4: {
			color: Colours.bodyText,
			fontSize: "80%",
		},
		h5: {
			color: Colours.bodyText,
			fontSize: "120%",
			marginTop: 20,
		},
		h6: {
			color: Colours.bodyText,
			fontSize: "120%",
		},
	},
	palette: {
		primary: {
			main: Colours.primary,
		},
		secondary: {
			main: Colours.secondary,
			contrastText: Colours.primary,
		},
	},
	status: {
		danger: "red",
	},
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(allReducers, composeEnhancer(applyMiddleware(thunk)));

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<Provider store={store}>
			<BrowserRouter>
				<CssBaseline />
				<App />
			</BrowserRouter>
		</Provider>
	</MuiThemeProvider>,
	rootElement
);
