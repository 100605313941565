import React from "react";
import { Colours } from "../helpers/Colours";
import { motion } from "framer-motion";

import Image from "../images/profile.jpg";

//Material
import { makeStyles } from "@material-ui/core";
import Title from "../components/Title";

// Components
import { aboutUsContainer, leftFade, rightFade } from "../animation";
import { useScroll } from "../useScroll";
// Styling

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.5s ease",
		minHeight: "100vh",
		fontWeight: 400,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	content: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			width: `100%`,
			flexDirection: "column",
		},
		"& img": {
			width: 350,
			height: 350,
			objectFit: "contain",
			boxShadow: `20px 20px 5px ${Colours.imageShadow}`,
			outline: `20px solid ${Colours.imageBorder}`,
			outlineOffset: -20,
			[theme.breakpoints.down("lg")]: {
				width: 200,
				height: 200,
				margin: 20,
				outline: `10px solid ${Colours.imageBorder}`,
				boxShadow: `10px 10px 5px ${Colours.imageShadow}`,
				outlineOffset: -10,
			},
			[theme.breakpoints.down("xs")]: {
				width: 200,
				height: 200,
				margin: 20,
				outline: `10px solid ${Colours.imageBorder}`,
				boxShadow: `10px 10px 5px ${Colours.imageShadow}`,
				outlineOffset: -10,
			},
		},
	},
	info: {
		fontSize: "1.5em",
		color: Colours.white,
		lineHeight: 1.5,
		marginRight: 100,
		[theme.breakpoints.down("lg")]: {
			// width: `100%`,
			marginLeft: 20,
			marginRight: 20,
			fontSize: 16,
		},
		[theme.breakpoints.down("xs")]: {
			width: `100%`,
			margin: 20,
			fontSize: 14,
		},
	},
}));

function AboutMe() {
	const classes = useStyles();
	const [element, controls] = useScroll();

	return (
		<section className={classes.root} id="section2">
			<Title title="About Me" subtitle="- My Career Path -" />
			<motion.div
				className={classes.content}
				ref={element}
				animate={controls}
				variants={aboutUsContainer}
				initial="hidden"
			>
				<motion.div
					className={classes.info}
					ref={element}
					animate={controls}
					variants={aboutUsContainer}
					initial="hidden"
				>
					<motion.p variants={leftFade} style={{ marginBottom: 20 }}>
						I started my Software Development career creating games in Unity
						(C#) with guidance from Torchbearer Interactive, Huddersfield. As my
						love for programming evolved, I then pursued front-end web
						development using HTML, CSS and Javascript before learning front-end
						Javascript frameworks.
					</motion.p>
					<motion.p variants={leftFade}>
						Previously working as a Pharmacy Dispenser for 5 years, I realised
						that my career path must involve the creation of a fantastic product
						whilst being innovative. I have always been a very creative
						individual and I love bringing ideas to life! Through Pharmacy, I
						developed the skills to communicate effectively and the ability to
						work very well with a team under pressure.
					</motion.p>
				</motion.div>
				<motion.img variants={rightFade} src={Image} alt="my profile" />
			</motion.div>
		</section>
	);
}

export default AboutMe;
