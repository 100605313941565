import React from "react";
// import { Colours } from "../helpers/Colours";
import { motion } from "framer-motion";

import { Projects } from "../content/Content";

//Material
import { makeStyles } from "@material-ui/core";
import Title from "../components/Title";

// Icons
import ProjectItem from "../components/ProjectItem";

const useStyles = makeStyles((theme) => ({
	root: {
		transition: "all 0.5s ease",
		minHeight: "100vh",
		fontWeight: 400,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	content: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "column",
		width: "100%",
	},
	itemText: {
		fontSize: "1.5em",
		whiteSpace: "nowrap",
	},
	sectionWrapper: {
		display: "flex",
		marginBottom: 20,
		alignItems: "center",
	},
}));

function AboutMe() {
	const classes = useStyles();

	return (
		<section className={classes.root} id="section1">
			<Title title="Projects" subtitle="- Web / Games -" />
			<motion.div className={classes.content}>
				{Projects.map((el) => {
					return (
						<ProjectItem
							key={el.id}
							id={el.id}
							title={el.title}
							image={el.image}
							description={el.description}
							link={el.link}
							skills={el.skills}
						/>
					);
				})}
			</motion.div>
		</section>
	);
}

export default AboutMe;
