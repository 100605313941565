import React from "react";
import { Colours } from "../helpers/Colours";
import { makeStyles } from "@material-ui/core";
import NavButtons from "./NavButtons";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
	container: {
		position: "fixed",
		top: 0,
		right: 0,
		width: "25%",
		height: "100%",
		backgroundColor: Colours.NavBackground,
		transform: "translate(100%)",
		transition: "all 0.5s ease",
		Opacity: 0,
		zIndex: 200,
		[theme.breakpoints.down("xs")]: {
			width: "40%",
		},
		"& h1": {
			padding: 10,
			paddingLeft: 15,
			backgroundColor: Colours.darkBackground,
		},
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "column",
	},
	active: {
		transform: "translate(0%)",
		Opacity: 1,
	},
	closeIcon: {
		marginTop: 10,
		marginLeft: 15,
		color: Colours.bodyText,
	},
}));

function NavMenu({ menu, handleMenuClose, handleMenuOpen }) {
	const classes = useStyles();

	return (
		<div className={`${classes.container} ${menu && classes.active}`}>
			<Link
				className={classes.item}
				to="section0"
				spy={true}
				smooth={true}
				duration={750}
				onClick={() => handleMenuClose()}
			>
				<h1>Home</h1>
			</Link>

			<div className={classes.buttonContainer}>
				<NavButtons handleMenuClose={handleMenuClose} />
			</div>
			<div className={classes.closeIcon} onClick={() => handleMenuClose()}>
				Close
			</div>
		</div>
	);
}

export default NavMenu;
